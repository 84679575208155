<template>
  <div class="blogs">
    <div class="max-width mt-7">
      <!-- filters -->
      <div class="d-flex align-center justify-space-between mb-3">
        <span class="d-block greytext--text font-27">{{$t('blogsPage.ourBlogs')}}</span>
        <div class="d-flex">
          <select-filter
            placeholder="Filter"
            :rules="[]"
            class="mx-3"
          ></select-filter>
          <select-filter placeholder="Sort By" :rules="[]"></select-filter>
        </div>
      </div>

      <!-- main blog -->
      <div class="white radius-30 pa-4">
        <v-row class="px-3 py-2">
          <v-col cols="12" md="6" class="pa-0">
            <div class="radius-15">
              <img
                width="100%"
                height="100%"
                :src="require('@/assets/images/blog-img.png')"
                alt=""
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" class="px-4 py-0 flex-between-column" >
            <div class="px-3">
                
            <span class="font-700 font-40 d-block darktext--text">{{
              blog.title
            }}</span>
            <p class="font-21 line-40 darktext--text">{{ blog.desc }}</p>
            </div>
            <logo-date class=""></logo-date>
          </v-col>
        </v-row>
      </div>

      <!-- top 3 blogs -->
      <v-row class="mt-14">
        <v-col cols="12" md="4" v-for="(blog, i) in [1, 12, 1]" :key="i">
          <blog></blog>
        </v-col>
      </v-row>

      <!-- about anthing -->
      <span class="d-block font-700 darktext--text font-32 mt-14 mb-7"
        >About anything</span
      >
      <v-row>
        <v-col cols="12" md="4" v-for="(blog, i) in [1, 12, 1]" :key="i">
          <blog></blog>
        </v-col>
      </v-row>

      <!-- show more -->
      <div class="text-center mt-14 mb-14">
        <div class="white radius-10 px-6 py-3 d-inline-block cursor-pointer">
          <span class="font-16 blacktext--text">{{$t('blogsPage.showMoreCountries')}}</span>
        </div>
      </div>

      <!-- contact -->
      <get-in-touch></get-in-touch>
    </div>
  </div>
</template>

<script>
import SelectFilter from "../../components/base/Select.vue";
import GetInTouch from "../../components/core/GetInTouch.vue";
import LogoDate from '../../components/core/LogoDate.vue';
import Blog from "./components/Blog.vue";
export default {
  components: { GetInTouch, Blog, SelectFilter, LogoDate },
  data: () => ({
    blog: {
      title: "Exposed New Opportunities New Opportunities Exposed",
      desc: "After your account is verified by the platform, our team will start the recruitment process and look for an appropriate match for you among member medical institutions. ",
    },
  }),
};
</script>

<style lang="scss">
.blogs {
  .base-select {
    width: 130px;
  }
}
</style>
