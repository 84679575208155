<template>
  <div class="logo-date d-flex align-center">
    <img :src="require('@/assets/images/logo-mini.png')" alt="">
    <div>
      <span class="d-block font-20 darktext--text">Medical Job</span>
      <span class="d-block font-15 lightgreytext--text">Jun 27, 2022</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>