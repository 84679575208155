<template>
  <div class="blog white radius-30 pa-3">
    <img
      width="100%"
      class="object-cover radius-15"
      height="300px"
      :src="require('@/assets/images/blog-img.png')"
      alt=""
    />
    <div class="px-1">
    <span class="font-700 font-27 d-block darktext--text mt-2"
      >Exposed New Opportunities New Opportunities Exposed</span
    >
    <p class="font-21 darktext--text mt-5">
      After your account is verified by the platform, our team will start the
      recruitment process and look for an
    </p>
    <logo-date class="mt-8"></logo-date>        
    </div>
  </div>
</template>

<script>
import LogoDate from "../../../components/core/LogoDate.vue";
export default {
  components: { LogoDate },
};
</script>

<style lang="scss" >
.blog{
    box-shadow: 0px 56px 80px 0px #ADC7EE66 !important;

}
</style>
